import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import InfoIcon from '@mui/icons-material/Info';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Dialog, DialogContent, DialogTitle, DialogActions, Snackbar } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { useState } from 'react';
import { API, graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import { Auth } from '@aws-amplify/auth';
import { getListSettings, startSearch } from '../graphql/queries';
import { resetGlobalState, useGlobalState } from '../helper/state';
import { UserRole } from '../models';
import React from 'react';
import MuiAlert from '@mui/material/Alert';

type ApplicationBarProps = {
  drawerWidth: Number,
  sideMenuOpen: boolean,
  setSideMenuOpen: Function  
};


const ApplicationBar = (props: ApplicationBarProps) => {

  // show or hide info dialog (shows current sanctionlist version)
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  // anchor element for profile menu. if != null profile menu will show up
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // current sanction list version (loaded when user clicks on info icon in app bar)
  const [currentSanctionlistVersion, setCurrentSanctionlistVersion] = useState<any>({});
  // circular loading indicator
  const [loading, setLoading] = useGlobalState("showLoadingOverlay");

  // Configure meesage for snackbar alerts. If show=true message will be shown. Possible types: success, error...
  const [showToast, setShowToast] = useState({ message: "", show: false, type: "" });

  const [loggedInUser] = useGlobalState("loggedInUser");
  
  const Alert = React.forwardRef(function Alert(props: any, ref: any) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  /**
   * Open side menu
   */
  const handleDrawerToggle = () => {
    props.setSideMenuOpen(!props.sideMenuOpen);
  };


  
  /**
   * Handle click on profile menu
   * 
   * @param event 
   */
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Open Info Dialog to show current version of sanction list.
   * 
   * @param event 
   */
  const handleOpenInfo = async (event: any) => {
    const response = await API.graphql(graphqlOperation(getListSettings, { listType: 'EU' })) as GraphQLResult<any>;
    
    if (response.data.getListSettings) {
      setCurrentSanctionlistVersion(response.data.getListSettings);
      setOpenInfoDialog(true);
    }
  };

  /**
   * Handle closing of profile menu
   */
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  /**
   * Handle click on update button in info dialog.
   */
  // const handleUpdateESIndex = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await API.graphql(graphqlOperation(updateESIndex)) as GraphQLResult<any>;
  //     if (response.data.updateESIndex) {
  //       setCurrentSanctionlistVersion(response.data.updateESIndex);
  //     }
  //   } catch (err) {      
  //     console.error(err);
  //     const error = err as any;
  //     if (error.errors[0].message === 'QUOTA_EXCEEDED') {
  //       setShowToast({ message: 'Aktualisierung nicht möglich!\n\nDu hast die maximale Anzahl an manuellen Updates pro Tag erreicht.', type: 'error', show: true });
  //     } else {
  //       setShowToast({ message: 'Aktualisierung nicht möglich!\n\nEin unerwarteter Fehler ist aufgetreten.', type: 'error', show: true });
  //     }
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  /**
   * Handle click on search button
   */
  const handleSearchClick = async () => {
    setLoading(true);
    try {
      await API.graphql({
        query: startSearch
      }) as GraphQLResult<any>;      
       setShowToast({ message: 'Prüfung gestartet!\n\nDie Prüfjobs werden im Hintergrund ausgeführt. Prüfe die Ergebnisliste, ob es Treffer gegeben hat.', type: 'success', show: true });
    } catch (err) {
      console.error(err);
      const error = err as any;
      if (error.errors[0].message === 'QUOTA_EXCEEDED') {
        setShowToast({ message: 'Prüfung nicht möglich!\n\nDu hast die maximale Anzahl an manuellen Prüfungen pro Tag erreicht.', type: 'error', show: true });
      } else {
        setShowToast({ message: 'Prüfung nicht möglich!\n\nEin unerwarteter Fehler ist aufgetreten.', type: 'error', show: true });
      }
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handle click on signout in profile menu
   */
  const handleSignOut = async () => {
    handleCloseMenu();
    resetGlobalState();
    await Auth.signOut();
  }

  /**
   * Close snackbar alert.
   * 
   * @param event 
   * @param reason 
   * @returns 
   */
  const handleSnackbarClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(prev => ({ ...prev, show: false }));
  };

  return (
    <>
      <MuiAppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar variant="dense">
          <IconButton edge="start" onClick={handleDrawerToggle} color="inherit" aria-label="menu" sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" component="div" sx={{ flexGrow: 1 }}>
            Sanktionslistenprüfung
          </Typography>
          {loggedInUser?.role === UserRole.ADMIN && <Button variant='contained' onClick={handleSearchClick}>Prüfung ausführen</Button>}
          <div>
            <IconButton
              size="large"
              onClick={handleOpenInfo}
              color="inherit"
            >
              <InfoIcon />
            </IconButton>
            <IconButton
              size="large"
              onClick={handleOpenMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >              
              <MenuItem onClick={handleSignOut}>Abmelden</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </MuiAppBar>
      <Dialog open={openInfoDialog} onClose={(event) => setOpenInfoDialog(false)}>
        <DialogTitle>
          Aktuelle Version der Sanktionsliste
        </DialogTitle>
        <DialogContent>
          <List dense={false}>
            <ListItem>
              <ListItemText
                primary={`Sanktionsliste: ${currentSanctionlistVersion.listType} (${currentSanctionlistVersion.currentId})`}
                secondary={`vom: ${new Date(currentSanctionlistVersion.currentGenerationDate).toLocaleString()}, aktualisiert am:  ${new Date(currentSanctionlistVersion.lastUpdateDate).toLocaleString()}`}
              />
            </ListItem>
          </List>
        </DialogContent>        
      </Dialog>
      <Snackbar open={showToast.show} autoHideDuration={8000} onClose={handleSnackbarClose} sx={{maxWidth: '400px', whiteSpace: 'pre-wrap'}}>
        <Alert onClose={handleSnackbarClose} severity={showToast.type} sx={{ width: '100%' }}>
          {showToast.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ApplicationBar;