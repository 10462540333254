// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ListType = {
  "EU": "EU"
};

const UserRole = {
  "USER": "USER",
  "ADMIN": "ADMIN"
};

const UserType = {
  "ORGANIZATIONAL": "ORGANIZATIONAL",
  "GUEST": "GUEST"
};

const EmailNotificationStatus = {
  "OFF": "OFF",
  "SEND_HIT": "SEND_HIT",
  "SEND_DAILY": "SEND_DAILY"
};

const RequestType = {
  "GET": "GET",
  "POST": "POST",
  "PUT": "PUT"
};

const LogStatus = {
  "RUNNING": "RUNNING",
  "ACTIVE_OPEN": "ACTIVE_OPEN",
  "ACTIVE_CONFIRMED": "ACTIVE_CONFIRMED",
  "NO_DATA": "NO_DATA",
  "ARCHIVED": "ARCHIVED"
};

const HitStatus = {
  "NEW": "NEW",
  "CONFIRMED": "CONFIRMED"
};

const { Settings, Users, JobSettings, Log, Hits, CheckedEntities, Whitelist } = initSchema(schema);

export {
  Settings,
  Users,
  JobSettings,
  Log,
  Hits,
  CheckedEntities,
  Whitelist,
  ListType,
  UserRole,
  UserType,
  EmailNotificationStatus,
  RequestType,
  LogStatus,
  HitStatus
};