import { Flex, Link, Button, Image, useAuthenticator, useTheme } from "@aws-amplify/ui-react";
import awsExports from "../aws-exports";

export function SignInFooter() {
  const { toResetPassword } = useAuthenticator();
  const { tokens } = useTheme();

  const handleFederatedSignIn = async () => {    
    window.sessionStorage.setItem("showLoginOverlay", "true");
    window.location.assign(`https://${window.sessionStorage.getItem('cognitoHostedUIUrl')}/oauth2/authorize?client_id=${awsExports.aws_user_pools_web_client_id}&response_type=code&scope=email+openid+profile&redirect_uri=${encodeURIComponent(window.sessionStorage.getItem('loginCallbackUrl')!)}`);    
  }

  return (
    <Flex direction={"column"} justifyContent="center" padding={`0 0 ${tokens.space.medium}`} alignItems={"center"}>
      {/* <Button onClick={handleFederatedSignIn}>
        <Image src="https://img.icons8.com/ios/30/000000/microsoft.png" alt="Microsoft" style={{marginRight: 10}} />
        Mit Microsoft Login anmelden
      </Button> */}
      <Link onClick={toResetPassword}>Passwort zurücksetzen</Link>
    </Flex>
  );
}
