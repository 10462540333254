export const updateLogStatus = /* GraphQL */ `
  mutation UpdateLog(
    $input: UpdateLogInput!
    $condition: ModelLogConditionInput
  ) {
    updateLog(input: $input, condition: $condition) {
      id      
      updatedBy {
        id        
      }
      status      
    }
  }
`;