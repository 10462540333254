export const getLogWithHits = /* GraphQL */ `
  query GetLogWithHits($id: ID!) {
    getLog(id: $id) {
      id      
      hits {
        items {
          id
          hits
          hitsWhitelisted
          numberOfHits
          numberOfHitsWhitelisted
          referenceId
          referenceWholeName
          referenceFirstName
          referenceLastName
          referenceCountry
          referenceCity
          status
        }
      }      
    }
  }
`;

export const listLogsShort = /* GraphQL */ `
  query ListLogs(
    $id: ID
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLogs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        jobName
        date
        listType
        listDate
        listId
        numberOfHits
        numberOfHitsWhitelisted
        numberOfSearchEntities        
      }
      nextToken
    }
  }
`;

export const logsByArchivedStatusShort = /* GraphQL */ `
  query LogsByArchivedStatus(
    $status: LogStatus!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LogsByArchivedStatus(
      status: $status
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobName
        date
        listType
        listDate
        listId
        numberOfHits
        numberOfHitsWhitelisted
        numberOfSearchEntities   
      }
      nextToken
    }
  }
`;