import { useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Log } from "../models";
import HitList from "./hitList";
import { LogStatus } from "../models";
import LogTable from "./logTable";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const LogList = () => {

  
  const [selectedLogId, setSelectedLogId] = useState<string>("");
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };


  const handleDetailsClick = async (event: any, log: Log) => {
    setSelectedLogId(log.id);
  }

  const handleCloseHits = () => {
    setSelectedLogId("");    
  };

  interface TabPanelProps {
    logStatus: LogStatus;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { logStatus, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <LogTable logListStatus={logStatus} handleDetailsCallback={handleDetailsClick}></LogTable>
        )}
      </div>
    );
  }

  return (
    <>
      <Tabs value={tabValue} onChange={handleTabChange} centered variant="fullWidth">
        <Tab label="Zu Bearbeiten" />
        <Tab label="Abgeschlossen" />
        <Tab label="Laufend" />
        <Tab label="Keine Daten" />
      </Tabs>
      <TabPanel value={tabValue} index={0} logStatus={LogStatus.ACTIVE_OPEN} />
      <TabPanel value={tabValue} index={1} logStatus={LogStatus.ACTIVE_CONFIRMED} />
      <TabPanel value={tabValue} index={2} logStatus={LogStatus.RUNNING} />
      <TabPanel value={tabValue} index={3} logStatus={LogStatus.NO_DATA} />
      <Dialog open={selectedLogId !== ""} onClose={handleCloseHits} maxWidth={"lg"} fullWidth>
        <DialogTitle>Trefferliste</DialogTitle>
        <DialogContent>
          <HitList logId={selectedLogId}></HitList>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LogList;