import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { API, graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import React, { Fragment, useEffect, useState } from "react";
import { getLogWithHits } from "../graphql/own_queries";
import { addToWhitelist as gqlAddToWhitelist, removeFromWhitelist as gqlRemoveFromWhitelist} from "../graphql/mutations";
import { useGlobalState } from "../helper/state";
import { Hits } from "../helper/interfaces";
import Tooltip from '@mui/material/Tooltip';
import { Checkbox, Stack, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import LoadingButton from '@mui/lab/LoadingButton';

type HitListProps = {
  logId: string
}

export const getLog = /* GraphQL */ `
  query GetLog($id: ID!) {
    getLog(id: $id) {
      id
      jobName
      date
      listType
      listDate
      listId
      numberOfHits
      note
      updatedBy {
        id
        email
        role
        type
        cognitoStatus
        createdAt
        updatedAt
      }
      hits {
        nextToken
      }
      createdAt
      updatedAt
      logUpdatedById
    }
  }
`;


const HitList = (props: HitListProps) => {

  const [hitList, setHitList] = useState<Hits[]>([] as Hits[]);
  // State for showing loading overlay
  const [showLoadingOverlay, setShowLoadingOverlay] = useGlobalState("showLoadingOverlay");
  // Configure meesage for snackbar alerts. If show=true message will be shown. Possible types: success, error...
  const [showToast, setShowToast] = useState({ message: "", show: false, type: "" });

  // Selected hits which should be added to whitelist
  const [addToWhitelist, setAddToWhitelist] = useState<any>({});
  const [checkAll, setCheckAll] = useState<any>({});

  const [removeFromWhitelist, setRemoveFromWhitelist] = useState<any>({});
  const [checkAllRemove, setCheckAllRemove] = useState<any>({});

  // Configure alert snackbar
  const Alert = React.forwardRef(function Alert(props: any, ref: any) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    if (props.logId) {
      fetchHitList(props.logId);
    }
  }, [props.logId]);

  const fetchHitList = async (logId: string) => {
    
    try {
      setShowLoadingOverlay(true);
            
      const response = await API.graphql(graphqlOperation(getLogWithHits, { id: logId })) as GraphQLResult<any>;
      
      for (let i = 0; i < response.data.getLog?.hits?.items.length; i++) {
        const curHit = response.data.getLog?.hits?.items[i];
        curHit.hits = JSON.parse(curHit.hits);
      }
      setHitList(response.data.getLog?.hits?.items || []);
    } catch (err) {
      console.error(err);
    } finally {
      setShowLoadingOverlay(false);
    }
  };

  const handleAddToWhitelistCheckbox = async (event: any, hitItem: Hits, hitDetail: any) => {
    setAddToWhitelist((prev: any) => ({ ...prev, [`${hitItem.referenceWholeName}_#_${hitDetail.logicalId}`]: event.target.checked }));
  };

  const handleAddAllToWhitelistCheckbox = async (event: any, hitItem: Hits) => {
    console.log(hitItem);
    setCheckAll((prev: any) => ({ ...prev, [`${hitItem.referenceWholeName}`]: event.target.checked }));
    for (let i = 0; i < hitItem.hits!.length; i++) {
      handleAddToWhitelistCheckbox(event, hitItem, hitItem.hits![i]);
    }
  };

  const handleAddToWhitelist = async (event: any) => {
    setShowLoadingOverlay(true);
    try {

      const input = Object.keys(addToWhitelist).map(value => {
        const splitValue = value.split('_#_');
        return {
          logicalId: splitValue[1],
          referenceWholeName: splitValue[0],
        };
      });
      await API.graphql(graphqlOperation(gqlAddToWhitelist, { input: input })) as GraphQLResult<any>;
      setShowToast({ message: "Die Whitelist-Einträge wurden erfolgreich hinzugefügt.", show: true, type: "success" });
      setAddToWhitelist({});
      setCheckAll({});
    } catch (err) {            
      console.error(err);
      setShowToast({ message: "Die Whitelist-Einträge konnten nicht hinzugefügt werden.", show: true, type: "error" });
    } finally {      
      setShowLoadingOverlay(false);
    }
  };

  const handleRemoveFromWLCheckbox = async (event: any, hitItem: Hits, hitDetail: any) => {
    setRemoveFromWhitelist((prev: any) => ({ ...prev, [`${hitItem.referenceWholeName}_#_${hitDetail.logicalId}`]: event.target.checked }));
  };

  const handleRemoveAllFromWhitelistCheckbox = async (event: any, hitItem: Hits) => {
    console.log(hitItem);
    const whitelist = JSON.parse(hitItem.hitsWhitelisted!);
    setCheckAllRemove((prev: any) => ({ ...prev, [`${hitItem.referenceWholeName}`]: event.target.checked }));
    for (let i = 0; i < whitelist.length; i++) {
      handleRemoveFromWLCheckbox(event, hitItem, whitelist[i]);
    }
  };

  const handleRemoveFromWLClick = async (event: any) => {
     setShowLoadingOverlay(true);
    try {

      const input = Object.keys(removeFromWhitelist).map(value => {
        const splitValue = value.split('_#_');
        return {
          logicalId: splitValue[1],
          referenceWholeName: splitValue[0],
        };
      });
      await API.graphql(graphqlOperation(gqlRemoveFromWhitelist, { input: input })) as GraphQLResult<any>;
      setShowToast({ message: "Die Whitelist-Einträge wurden erfolgreich entfernt.", show: true, type: "success" });
      setRemoveFromWhitelist({});
      setCheckAllRemove({});
    } catch (err) {            
      console.error(err);
      setShowToast({ message: "Die Whitelist-Einträge konnten nicht entfernt werden.", show: true, type: "error" });
    } finally {      
      setShowLoadingOverlay(false);
    }
  };

  /**
   * Close snackbar alert.
   * 
   * @param event 
   * @param reason 
   * @returns 
   */
  const handleSnackbarClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(prev => ({ ...prev, show: false }));
  };


  const Row = (props: { hit: Hits }) => {
    const { hit } = props;
    const innerHits = hit.hits as any;
    const hitsWhitelisted = JSON.parse(hit.hitsWhitelisted as string);
    const [open, setOpen] = useState(hit.menuOpen);

    const handleOpen = () => {
      hit.menuOpen = !hit.menuOpen;
      setOpen(hit.menuOpen);
    }

    return (
      <Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={handleOpen}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell sx={{ maxWidth: "300px" }} component="th" scope="row">
            {hit.referenceWholeName}
          </TableCell>
          <TableCell sx={{ maxWidth: "400px" }}>{hit.referenceId?.join(', ')}</TableCell>
          <TableCell align='center'>{hit.numberOfHitsWhitelisted}</TableCell>
          <TableCell align='center'>{hit.numberOfHits}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                {innerHits?.length! > 0 &&
                  <>
                    <Table size="small" aria-label="purchases">
                      <TableHead>
                        <TableRow>
                          <TableCell>Typ</TableCell>
                          <TableCell>LogicalId</TableCell>
                          <TableCell>Match</TableCell>
                          <TableCell>Alias</TableCell>
                          <TableCell align='center' width={"80px"}>                            
                            <Tooltip title="Alle zur Whitelist hinzufügen">
                              <Checkbox
                                onChange={(event) => handleAddAllToWhitelistCheckbox(event, hit)}
                                checked={checkAll[`${hit.referenceWholeName}`]}
                              />
                            </Tooltip>
                          </TableCell>
                        
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {innerHits?.map((detail: any) => (
                          <TableRow key={detail.logicalId}>
                            <TableCell component="th" scope="row" sx={{ verticalAlign: 'top' }}>
                              {detail.subjectTypeCode.code}
                            </TableCell>
                            <TableCell sx={{ verticalAlign: 'top' }}>{detail.logicalId}</TableCell>
                            <TableCell sx={{ verticalAlign: 'top' }}>{detail.match.wholeName.join(', ')}</TableCell>
                            <TableCell sx={{ whiteSpace: "pre-wrap", verticalAlign: 'top' }}>
                              {(detail.nameAlias.map((alias: any) => `(${alias.logicalId}) ${alias.wholeName}`)).join("\n")}
                            </TableCell>
                            <TableCell align={"center"} sx={{ verticalAlign: 'top' }}>
                              <Tooltip title="Zur Whitelist hinzufügen">
                                <Checkbox
                                  onChange={(event) => handleAddToWhitelistCheckbox(event, hit, detail)}
                                  checked={addToWhitelist[`${hit.referenceWholeName}_#_${detail.logicalId}`]}
                                />
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                }
                
                {hitsWhitelisted.length > 0 &&
                  <>
                    <Typography variant='body1' sx={{ fontWeight: "bold", marginLeft: "5px", marginTop: "10px" }}>Treffer auf Whitelist</Typography>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Typ</TableCell>
                          <TableCell>LogicalId</TableCell>
                          <TableCell>Match</TableCell>
                          <TableCell>Alias</TableCell>
                          <TableCell align='center' width={"80px"}>                            
                            <Tooltip title="Alle von Whitelist entfernen">
                              <Checkbox
                                onChange={(event) => handleRemoveAllFromWhitelistCheckbox(event, hit)}
                                checked={checkAllRemove[`${hit.referenceWholeName}`]}
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {hitsWhitelisted.map((detail: any) => (
                          <TableRow key={detail.logicalId}>
                            <TableCell component="th" scope="row" sx={{ verticalAlign: 'top' }}>
                              {detail.subjectTypeCode.code}
                            </TableCell>
                            <TableCell sx={{ verticalAlign: 'top' }}>{detail.logicalId}</TableCell>
                            <TableCell sx={{ verticalAlign: 'top' }}>{detail.match.wholeName.join(', ')}</TableCell>
                            <TableCell sx={{ whiteSpace: "pre-wrap", verticalAlign: 'top' }}>
                              {(detail.nameAlias.map((alias: any) => `(${alias.logicalId}) ${alias.wholeName}`)).join("\n")}
                            </TableCell>
                            <TableCell align={"center"} sx={{ verticalAlign: 'top' }}>
                              <Tooltip title="Von der Whitelist entfernen">
                                <Checkbox
                                  onChange={(event) => handleRemoveFromWLCheckbox(event, hit, detail)}
                                  checked={removeFromWhitelist[`${hit.referenceWholeName}_#_${detail.logicalId}`]}
                                />
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                }
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    )
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell width={"20px"} />
              <TableCell>Referenzname</TableCell>
              <TableCell>Referenz-ID</TableCell>
              <TableCell align='center'>#Treffer WL</TableCell>
              <TableCell align='center'>#Treffer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hitList.map((row) => (
              <Row key={row.id} hit={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
     
      <Stack direction="row" spacing={2} textAlign="center" justifyContent={'center'} margin="20px">
        <LoadingButton
          variant='contained'
          endIcon={<AddIcon />}
          onClick={handleAddToWhitelist}
          loading={showLoadingOverlay}
          loadingPosition="end"
          sx={{ width: "380px" }}
          disabled={!Object.values(addToWhitelist).includes(true)}
        >
          ({Object.values(addToWhitelist).filter(value => value).length}) auf Whitelist speichern
        </LoadingButton>
        <LoadingButton
          color='error'
          variant='contained'
          endIcon={<RemoveIcon />}
          onClick={handleRemoveFromWLClick}
          loading={showLoadingOverlay}
          loadingPosition="end"
          sx={{ width: '420px' }}
          disabled={!Object.values(removeFromWhitelist).includes(true)}
        >
          ({Object.values(removeFromWhitelist).filter(value => value).length}) von Whitelist entfernen
        </LoadingButton>
      </Stack>

      <Snackbar open={showToast.show} autoHideDuration={5000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={showToast.type} sx={{ width: '100%' }}>
          {showToast.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default HitList;