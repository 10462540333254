import { useState, useEffect } from "react";

import LoadingOverlay from "./loadingOverlay";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton/IconButton";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import RefreshIcon from '@mui/icons-material/Refresh';
import RemoveIcon from '@mui/icons-material/Remove';
import { useGlobalState } from "../helper/state";
import { API, GraphQLResult, graphqlOperation } from "@aws-amplify/api";
import { Whitelist } from "../models";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import React from "react";
import { listWhitelists } from "../graphql/queries";
import { deleteWhitelist } from "../graphql/mutations";
import { Tooltip } from "@mui/material";


interface WhitelistTableProps {
  
}

const WhitelistTable = (props: WhitelistTableProps) => {

  // List of logs from DB
  const [whitelist, setWhitelist] = useState<Whitelist[]>([] as Whitelist[]);
  // State for showing loading overlay
  const [showLoadingOverlay, setShowLoadingOverlay] = useGlobalState("showLoadingOverlay");
  
  // Configure meesage for snackbar alerts. If show=true message will be shown. Possible types: success, error...
  const [showToast, setShowToast] = useState({ message: "", show: false, type: "" });

  // Configure alert snackbar
  const Alert = React.forwardRef(function Alert(props: any, ref: any) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  

  /**
  * Load LogList from DB on page load
  */
  useEffect(() => {
    fetchWhitelist();
  }, []);


  const fetchWhitelist = async (force: boolean = true) => {
    try {

      setShowLoadingOverlay(true);
      let nextToken = null;
      let result: Whitelist[] = [];
      do {
        let response: any = null;
        if (nextToken) {
          response = await API.graphql(graphqlOperation(listWhitelists, { limit: 10, nextToken: nextToken })) as GraphQLResult<any>;
        } else {
          response = await API.graphql(graphqlOperation(listWhitelists, { limit: 10 })) as GraphQLResult<any>;
        }
        nextToken = response.data.listWhitelists.nextToken;
        result = [...result, ...response.data.listWhitelists.items];
      } while (nextToken);
      
      setWhitelist(sortList(result));
      
    } catch (err) {
      console.error(err);
    } finally {
      setShowLoadingOverlay(false);
    }
  };

  const sortList = (list: Whitelist[]) => {
    const result = list.sort((a, b) => {
      if (a.referenceWholeName!.toLowerCase() < b.referenceWholeName!.toLowerCase()) return -1;
      if (a.referenceWholeName!.toLowerCase() > b.referenceWholeName!.toLowerCase()) return 1;
      return 0;
    });
    return result;
  }
  /**
   * Close snackbar alert.
   * 
   * @param event 
   * @param reason 
   * @returns 
   */
  const handleSnackbarClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(prev => ({ ...prev, show: false }));
  };
  
  const handleRemoveClick = async (event: any, whitelistEntry: Whitelist) => {
    setShowLoadingOverlay(true);
    try {
      const response = await API.graphql(graphqlOperation(deleteWhitelist, { input: { referenceWholeName: whitelistEntry.referenceWholeName, logicalId: whitelistEntry.logicalId } })) as GraphQLResult<any>;
      
      if (response.data.deleteWhitelist && response.data.deleteWhitelist.logicalId) {
        const newWhitelist: Whitelist[] = [...whitelist];
        const index = newWhitelist.findIndex((item) => item.logicalId === response.data.deleteWhitelist.logicalId && item.referenceWholeName === response.data.deleteWhitelist.referenceWholeName);
        if (index !== -1) {
          newWhitelist.splice(index, 1);
          setWhitelist(newWhitelist);
          setShowToast({ message: "Der Whitelist-Eintrag wurde entfernt.", show: true, type: "success" });
        }
      }
    } catch (err) {
      console.error(err);
      setShowToast({ message: "Fehler beim Entfernen des Whitelist-Eintrags", show: true, type: "error" });
    } finally {
      setShowLoadingOverlay(false);
    }
  };

  return (
    <>
      <LoadingOverlay />
      <TableContainer component={Paper} elevation={1} sx={{ minWidth: 650, maxWidth: "100%", marginTop: "20px" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <IconButton sx={{ marginRight: "15px" }} onClick={() => fetchWhitelist(true)}>
                  <RefreshIcon />
                </IconButton>
                Referenz-Name
              </TableCell>
              <TableCell align={"center"}>Logical-Id</TableCell>
              <TableCell align={"center"}>Aktion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              whitelist.map((wl: Whitelist, index: number) => (
                <TableRow key={`row_${index}`} hover>
                  <TableCell>{wl.referenceWholeName}</TableCell>
                  <TableCell align={"center"}>{wl.logicalId}</TableCell>
                  <TableCell align={"center"}>
                    <Tooltip title="Von der Whitelist entfernen">
                      <IconButton onClick={(event) => handleRemoveClick(event, wl)} size={"small"}>
                        <RemoveIcon fontSize="medium" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar open={showToast.show} autoHideDuration={5000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={showToast.type} sx={{ width: '100%' }}>
          {showToast.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default WhitelistTable;