
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import { useGlobalState } from '../helper/state';
import { UserRole } from '../models';

  
type SideMenuProps = {
  open: boolean,
  setOpen: Function,
  showContent: Function,
  width: number
};

const SideMenu = (props: SideMenuProps) => {

  // show or hide a submenu in the sidebar menu
  const [subListState, setSubListState] = useState<any>({});

  const [loggedInUser] = useGlobalState("loggedInUser");
  
  const drawerWidth = props.width;

  /**
   * Handle clicks on sidebar menu items
   * 
   * @param name 
   * @returns 
   */
  const handleMenuClick = (name: string) => (event: any) => {
    props.showContent(name);
  };

  /**
   * Handle clicks on menu items with submenu items
   * 
   * @param name 
   * @returns 
   */
  const handleSubmenuCollapse = (name: string) => (event: any) => {
    setSubListState({ ...subListState, [name]: !subListState[name] });
  };

  // Parameters for page rendering
  const listPaddingProps = { pb: 1, pt: 1 };
  const sublistPaddingProps = { pb: 0, pt: 0 };
  const listTypoProps = { fontSize: 14, fontWeight: 'normal' };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open={props.open}
    >
     <Toolbar />
      <List>
        <ListItem button key={"Ergebnisliste"} onClick={handleMenuClick("logList")} sx={listPaddingProps}>
          <ListItemIcon sx={{ minWidth: 34 }}>
            <FormatListBulletedIcon />
          </ListItemIcon>
          <ListItemText primary={"Ergebnisliste"} primaryTypographyProps={listTypoProps} />
        </ListItem>
        <ListItem button key={"Whitelist"} onClick={handleMenuClick("whitelist")} sx={listPaddingProps}>
          <ListItemIcon sx={{ minWidth: 34 }}>
            <FactCheckIcon />
          </ListItemIcon>
          <ListItemText primary={"Whitelist"} primaryTypographyProps={listTypoProps} />
        </ListItem>
      </List>
      {loggedInUser?.role === UserRole.ADMIN &&
        <>
      <Divider />
        
      <List>
        <ListItem button key={"Einstellungen"} onClick={handleSubmenuCollapse("settings")} sx={listPaddingProps}>
          <ListItemIcon sx={{ minWidth: 34 }}>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={"Einstellungen"} primaryTypographyProps={listTypoProps} />
          {subListState["settings"] ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={subListState["settings"]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>           
            {loggedInUser?.role === UserRole.ADMIN &&
              <ListItem button key={"Prüfungen"} sx={{ ...sublistPaddingProps, pl: 7 }} onClick={handleMenuClick("jobSettings")}>
                <ListItemText primary={"Prüfungen"} primaryTypographyProps={listTypoProps} />
              </ListItem>
            }
            <ListItem button key={"Benutzer"} sx={{ ...sublistPaddingProps, pl: 7 }} onClick={handleMenuClick("users")}>
                  <ListItemText primary={"Benutzer"} primaryTypographyProps={listTypoProps} />
                </ListItem>
          </List>
        </Collapse>
          
        </List>
        </>
      }
    </Drawer>
  );
}

export default SideMenu;