/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getJobSettings = /* GraphQL */ `
  query GetJobSettings($id: ID!) {
    getJobSettings(id: $id) {
      id
      name
      active
      url
      requestType
      startDateParameterName
      lastRunDate
      authorizationHeader
      mapping_firstName
      mapping_middleName
      mapping_lastName
      mapping_wholeName
      mapping_city
      mapping_countryDescription
      mapping_id
      fuzzy
      createdAt
      updatedAt
    }
  }
`;
export const listJobSettings = /* GraphQL */ `
  query ListJobSettings(
    $filter: ModelJobSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        active
        url
        requestType
        startDateParameterName
        lastRunDate
        authorizationHeader
        mapping_firstName
        mapping_middleName
        mapping_lastName
        mapping_wholeName
        mapping_city
        mapping_countryDescription
        mapping_id
        fuzzy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLog = /* GraphQL */ `
  query GetLog($id: ID!) {
    getLog(id: $id) {
      id
      jobName
      date
      createdAt
      listType
      listDate
      listId
      numberOfHits
      numberOfHitsWhitelisted
      note
      updatedBy {
        id
        email
        role
        type
        cognitoStatus
        sendEmailNotificationStatus
        createdAt
        updatedAt
      }
      status
      hits {
        nextToken
      }
      numberOfSearchEntities
      updatedAt
      logUpdatedById
    }
  }
`;
export const listLogs = /* GraphQL */ `
  query ListLogs(
    $id: ID
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLogs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        jobName
        date
        createdAt
        listType
        listDate
        listId
        numberOfHits
        numberOfHitsWhitelisted
        note
        status
        numberOfSearchEntities
        updatedAt
        logUpdatedById
      }
      nextToken
    }
  }
`;
export const logsByArchivedStatus = /* GraphQL */ `
  query LogsByArchivedStatus(
    $status: LogStatus!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LogsByArchivedStatus(
      status: $status
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobName
        date
        createdAt
        listType
        listDate
        listId
        numberOfHits
        numberOfHitsWhitelisted
        note
        status
        numberOfSearchEntities
        updatedAt
        logUpdatedById
      }
      nextToken
    }
  }
`;
export const getCheckedEntities = /* GraphQL */ `
  query GetCheckedEntities($id: ID!) {
    getCheckedEntities(id: $id) {
      id
      logId
      entities
      createdAt
      updatedAt
    }
  }
`;
export const listCheckedEntities = /* GraphQL */ `
  query ListCheckedEntities(
    $filter: ModelCheckedEntitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCheckedEntities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        logId
        entities
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const checkedEntitiesByLogId = /* GraphQL */ `
  query CheckedEntitiesByLogId(
    $logId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCheckedEntitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CheckedEntitiesByLogId(
      logId: $logId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        logId
        entities
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      email
      role
      type
      cognitoStatus
      sendEmailNotificationStatus
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        role
        type
        cognitoStatus
        sendEmailNotificationStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail(
    $email: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    GetUserByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        role
        type
        cognitoStatus
        sendEmailNotificationStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserByNotificationStatus = /* GraphQL */ `
  query GetUserByNotificationStatus(
    $sendEmailNotificationStatus: EmailNotificationStatus!
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    GetUserByNotificationStatus(
      sendEmailNotificationStatus: $sendEmailNotificationStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        role
        type
        cognitoStatus
        sendEmailNotificationStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHits = /* GraphQL */ `
  query GetHits($id: ID!) {
    getHits(id: $id) {
      id
      hits
      hitsWhitelisted
      numberOfHits
      numberOfHitsWhitelisted
      referenceId
      referenceWholeName
      referenceFirstName
      referenceLastName
      referenceCountry
      referenceCity
      status
      logId
      createdAt
      updatedAt
    }
  }
`;
export const listHits = /* GraphQL */ `
  query ListHits(
    $filter: ModelHitsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        hits
        hitsWhitelisted
        numberOfHits
        numberOfHitsWhitelisted
        referenceId
        referenceWholeName
        referenceFirstName
        referenceLastName
        referenceCountry
        referenceCity
        status
        logId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWhitelist = /* GraphQL */ `
  query GetWhitelist($referenceWholeName: String!, $logicalId: String!) {
    getWhitelist(
      referenceWholeName: $referenceWholeName
      logicalId: $logicalId
    ) {
      referenceWholeName
      logicalId
      createdAt
      updatedAt
    }
  }
`;
export const listWhitelists = /* GraphQL */ `
  query ListWhitelists(
    $referenceWholeName: String
    $logicalId: ModelStringKeyConditionInput
    $filter: ModelWhitelistFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWhitelists(
      referenceWholeName: $referenceWholeName
      logicalId: $logicalId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        referenceWholeName
        logicalId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const startSearch = /* GraphQL */ `
  query StartSearch($wholeName: String, $id: String) {
    startSearch(wholeName: $wholeName, id: $id)
  }
`;
export const getListSettings = /* GraphQL */ `
  query GetListSettings($listType: String) {
    getListSettings(listType: $listType) {
      listType
      currentId
      lastUpdateDate
      currentGenerationDate
    }
  }
`;
export const resendTempPassword = /* GraphQL */ `
  query ResendTempPassword($email: String) {
    resendTempPassword(email: $email)
  }
`;
