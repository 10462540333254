import { useState, useEffect } from "react";

import LoadingOverlay from "./loadingOverlay";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton/IconButton";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import RefreshIcon from '@mui/icons-material/Refresh';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CheckIcon from '@mui/icons-material/Check';
import { useGlobalState } from "../helper/state";
import { API, GraphQLResult, graphqlOperation } from "@aws-amplify/api";
import { CheckedEntities, Log } from "../models";
import { logsByArchivedStatusShort } from "../graphql/own_queries";
import { LogStatus } from "../models";
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { updateLogStatus } from "../graphql/own_mutations";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { checkedEntitiesByLogId } from "../graphql/queries";


interface LogTableProps {
  logListStatus: LogStatus,
  handleDetailsCallback: Function
}

const LogTable = (props: LogTableProps) => {

  // List of logs from DB
  const [logList, setLogList] = useState<Log[]>([] as Log[]);
  // CheckedEntities
  const [checkedEntities, setCheckedEntities] = useState<CheckedEntities | null>(null);
  const [checkedEntitiesEntries, setCheckedEntitiesEntries] = useState<any[]>([]);
  const [checkedEntitiesSelectedLogId, setCheckedEntitiesSelectedLogId] = useState<string>("");

  // State for showing loading overlay
  const [showLoadingOverlay, setShowLoadingOverlay] = useGlobalState("showLoadingOverlay");
  // Next token for loading next loglist results
  const [nextToken, setNextToken] = useState<string>("");
  const [checkedEntitiesNextToken, setCheckedEntitiesNextToken] = useState<string>("");

  const [loggedInUser] = useGlobalState("loggedInUser");

  // Configure meesage for snackbar alerts. If show=true message will be shown. Possible types: success, error...
  const [showToast, setShowToast] = useState({ message: "", show: false, type: "" });

  // Configure alert snackbar
  const Alert = React.forwardRef(function Alert(props: any, ref: any) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  

  /**
  * Load LogList from DB on page load
  */
  useEffect(() => {
    fetchLogList();
  }, []);


  const fetchLogList = async (force: boolean = true) => {
    try {
      setShowLoadingOverlay(true);
      let response: any = null;
      
      if (nextToken && !force) {
        response = await API.graphql(graphqlOperation(logsByArchivedStatusShort, { status: props.logListStatus, sortDirection: "DESC", limit: 10, nextToken: nextToken })) as GraphQLResult<any>;
      } else {
        response = await API.graphql(graphqlOperation(logsByArchivedStatusShort, { status: props.logListStatus, sortDirection: "DESC", limit: 10 })) as GraphQLResult<any>;
      }
      setNextToken(response.data.LogsByArchivedStatus.nextToken);
      // result = result.sort((a, b) => {
      //   if (a.date! < b.date!) return 1;
      //   if (a.date! > b.date!) return -1;
      //   return 0;
      // });
      if (force) {
        setLogList(response.data.LogsByArchivedStatus.items);
      } else {
        setLogList(prev => [...prev, ...response.data.LogsByArchivedStatus.items]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setShowLoadingOverlay(false);
    }
  };

  const handleShowCheckedEntities = async (event: any, logId: string) => {
    setShowLoadingOverlay(true);
    try {

      const response = await API.graphql(graphqlOperation(checkedEntitiesByLogId, { logId: logId, limit: 1 })) as GraphQLResult<any>;
      
      if (response.data.CheckedEntitiesByLogId && response.data.CheckedEntitiesByLogId.items?.length > 0) {
        setCheckedEntitiesEntries(JSON.parse(response.data.CheckedEntitiesByLogId.items[0].entities as string));        
      }
      setCheckedEntitiesNextToken(response.data?.CheckedEntitiesByLogId?.nextToken);
      setCheckedEntitiesSelectedLogId(logId);
    } catch (err) {
      console.error(err);
    } finally {
      setShowLoadingOverlay(false);
    }
  }

  const handleLoadMoreCheckedEntities = async () => {
    setShowLoadingOverlay(true);
    try {
      
      if (checkedEntitiesNextToken) {
        const response = await API.graphql(graphqlOperation(checkedEntitiesByLogId, { logId: checkedEntitiesSelectedLogId, limit: 1, nextToken: checkedEntitiesNextToken })) as GraphQLResult<any>;      
        if (response.data.CheckedEntitiesByLogId && response.data.CheckedEntitiesByLogId.items?.length > 0) {
          setCheckedEntitiesEntries(prev => [...prev, ...(JSON.parse(response.data.CheckedEntitiesByLogId.items[0].entities as string))]);
        }
        setCheckedEntitiesNextToken(response.data?.CheckedEntitiesByLogId?.nextToken);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setShowLoadingOverlay(false);
    }
  }

  /**
   * Close snackbar alert.
   * 
   * @param event 
   * @param reason 
   * @returns 
   */
  const handleSnackbarClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(prev => ({ ...prev, show: false }));
  };
  
  const handleConfirmClick = async (event: any, logId: string) => {
    setShowLoadingOverlay(true);
    try {
      const response = await API.graphql(graphqlOperation(updateLogStatus, { input: { id: logId, status: LogStatus.ACTIVE_CONFIRMED, logUpdatedById: loggedInUser?.id } })) as GraphQLResult<any>;
      
      if (response.data.updateLog && response.data.updateLog.id && response.data.updateLog.status === LogStatus.ACTIVE_CONFIRMED) {
        const newLogList: Log[] = [...logList];
        const index = newLogList.findIndex((item) => item.id === response.data.updateLog.id);
        if (index !== -1) {
          newLogList.splice(index, 1);
          setLogList(newLogList);
          setShowToast({ message: "Der Log-Eintrag wurde bestätigt.", show: true, type: "success" });
        }
      }
    } catch (err) {
      console.error(err);
      setShowToast({ message: "Fehler beim Bestätigen des Log-Eintrags", show: true, type: "error" });
    } finally {
      setShowLoadingOverlay(false);
    }
  };

  const handleCloseCheckedEntities = () => {
    setCheckedEntitiesEntries([]);
    setCheckedEntitiesSelectedLogId("");
  };

  return (
    <>
      <LoadingOverlay />
      <TableContainer component={Paper} elevation={1} sx={{ minWidth: 650, maxWidth: "100%", marginTop: "20px" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <IconButton sx={{ marginRight: "15px" }} onClick={() => fetchLogList(true)}>
                  <RefreshIcon />
                </IconButton>
                Job Name
              </TableCell>
              <TableCell>Ausführungsdatum</TableCell>
              <TableCell align={"center"}>Liste</TableCell>
              <TableCell align={"center"}>Liste-Id</TableCell>
              <TableCell align={"center"}>#Geprüft</TableCell>
              <TableCell align={"center"}>#Treffer-WL</TableCell>
              <TableCell align={"center"}>#Treffer</TableCell>
              <TableCell align={"center"}>Aktion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logList.map((log: Log, index: number) => (
              <TableRow key={`row_${index}`} hover>
                <TableCell>{log.jobName}</TableCell>
                <TableCell>{log.date ? `${new Date(log.date?.toString()).toLocaleDateString()} ${new Date(log.date?.toString()).toLocaleTimeString()}` : ''}</TableCell>
                <TableCell align={"center"}>{log.listType}</TableCell>
                <TableCell align={"center"}>{log.listId}</TableCell>
                <TableCell align={"center"}>{log.numberOfSearchEntities === -1 ? '' :
                  <Tooltip title="Suchentitäten anzeigen">
                    <Link component="button" onClick={(event) => handleShowCheckedEntities(event, log.id)}>
                      {log.numberOfSearchEntities}
                    </Link>
                  </Tooltip>
                }</TableCell>
                <TableCell align={"center"}>{log.numberOfHitsWhitelisted === -1 ? '' : log.numberOfHitsWhitelisted}</TableCell>
                <TableCell align={"center"}>{log.numberOfHits === -1 ? '' : log.numberOfHits}</TableCell>
                <TableCell align={"center"}>
                  {props.logListStatus !== LogStatus.RUNNING &&
                    <Tooltip title="Treffer anzeigen">
                      <IconButton onClick={(event) => props.handleDetailsCallback(event, log)} size={"small"}>
                        <ZoomInIcon fontSize="medium" />
                      </IconButton>
                    </Tooltip>
                  }
                  {props.logListStatus === LogStatus.ACTIVE_OPEN &&
                    <Tooltip title="Trefferliste bestätigen">
                      <IconButton onClick={(event) => handleConfirmClick(event, log.id)} size={"small"}>
                        <CheckIcon fontSize="medium" />
                      </IconButton>
                    </Tooltip>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {logList.length > 0 &&
        <Box textAlign={"center"} marginTop="20px" marginBottom="20px">
          <LoadingButton
            variant='contained'
            endIcon={<MoreHorizIcon />}
            onClick={() => fetchLogList(false)}
            loading={showLoadingOverlay}
            loadingPosition="end"
            disabled={!nextToken}
          >
            {nextToken ? "Mehr Ergebnisse" : "Keine weiteren Ergebnisse"}
          </LoadingButton>
        </Box>
      }
      <Snackbar open={showToast.show} autoHideDuration={5000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={showToast.type} sx={{ width: '100%' }}>
          {showToast.message}
        </Alert>
      </Snackbar>
      {checkedEntitiesEntries.length > 0 &&
        <Dialog open={checkedEntitiesEntries.length > 0} onClose={handleCloseCheckedEntities} maxWidth={"lg"} fullWidth>
          <DialogTitle>Suchentitäten</DialogTitle>
          <DialogContent>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{width: '300px'}}>Referenz-Id</TableCell>
                  <TableCell>Referenz-Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {checkedEntitiesEntries.map((entity: any) => (
                  <TableRow key={entity.id}>
                    <TableCell>{entity.id.join(', ')}</TableCell>
                    <TableCell>{entity.wholeName}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {checkedEntitiesEntries.length > 0 &&
              <Box textAlign={"center"} marginTop="20px" marginBottom="20px">
                <LoadingButton
                  variant='contained'
                  endIcon={<MoreHorizIcon />}
                  onClick={handleLoadMoreCheckedEntities}
                  loading={showLoadingOverlay}
                  loadingPosition="end"
                  disabled={!checkedEntitiesNextToken}
                >
                  {checkedEntitiesNextToken ? "Mehr Ergebnisse" : "Keine weiteren Ergebnisse"}
                </LoadingButton>
              </Box>
            }
          </DialogContent>
        </Dialog>
      }
    </>
  )
}

export default LogTable;