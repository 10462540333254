import { useGlobalState } from '../helper/state';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


function LoadingOverlay() {
  const [showLoadingOverlay, setShowLoadingOverlay] = useGlobalState("showLoadingOverlay");

  return (
    <>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoadingOverlay}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default LoadingOverlay;
