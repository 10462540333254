import React from 'react';
import ReactDOM from 'react-dom';


import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AmplifyProvider } from '@aws-amplify/ui-react';

import { BrowserRouter } from 'react-router-dom';
import awsExports from "./aws-exports";
import { Amplify, Auth } from 'aws-amplify';
import CssBaseline from '@mui/material/CssBaseline';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './App.css';

Amplify.configure(awsExports);
// Auth.configure({
//   region: awsExports.aws_project_region,
//   userPoolId: awsExports.aws_user_pools_id,
//   userPoolWebClientId: awsExports.aws_user_pools_web_client_id,
//   identityPoolId: awsExports.aws_cognito_identity_pool_id,
//   oauth: {
//     domain: "sso-staging.sanktionsliste.fullstackcreatives.net",
//     scope: ["email", "openid", "profile"],
//     redirectSignIn: "http://localhost:3000/",
//     redirectSignOut: "http://localhost:3000/logout/",
//     responseType: "code"
//   }
// });
Amplify.configure({  
  API: {
    graphql_headers: async () => ({
      'Authorization': `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    })
  }
});

const theme = createTheme({
  components: {
    MuiFormHelperText: {      
        // Use existing space / prevents shifting content below field
      styleOverrides: {
        root: {
          height: 0,
          marginTop: 0
          }
        }      
      }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <AmplifyProvider>
      <BrowserRouter>
        
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
        
      </BrowserRouter>
    </AmplifyProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
