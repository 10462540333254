
import WhitelistTable from "./whitelistTable";

const Whitelist = () => {

  return (
    <>
      <WhitelistTable />
    </>
  );
};

export default Whitelist;