/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:2eeb1647-9eda-423b-8921-2f335bd71f32",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_3L2kJgIAk",
    "aws_user_pools_web_client_id": "6guu6d2qurdltn5fp0cr7q42h3",
    "oauth": {
        "domain": "sso-wurzen.sanktionsliste.fullstackcreatives.net.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "https://wurzen-sanktionsliste.fullstackcreatives.net/",
        "redirectSignOut": "https://wurzen-sanktionsliste.fullstackcreatives.net/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 10,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://jr433rnugrbptdxkkspgtie5oa.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ra2c4qqwbrd65c3r53dif6sebm",
    "aws_cloud_logic_custom": [
        {
            "name": "loginConfigApi",
            "endpoint": "https://fyrmj82rtf.execute-api.eu-central-1.amazonaws.com/wurzen",
            "region": "eu-central-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "eu-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "quotas-wurzen",
            "region": "eu-central-1"
        },
        {
            "tableName": "CustomerSettings-wurzen",
            "region": "eu-central-1"
        },
        {
            "tableName": "SanktionslisteSettingsTable",
            "region": "eu-central-1"
        }
    ]
};


export default awsmobile;
