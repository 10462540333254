
import { useEffect, useState } from 'react';
import { useGlobalState } from '../helper/state';
import Users from '../components/users';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import EditJobSettings from '../components/editJobSettings';
import SideMenu from '../components/sideMenu';
import ApplicationBar from '../components/applicationBar';
import LogList from '../components/logList';
import { getUsers } from '../graphql/queries';
import { API, GraphQLResult, graphqlOperation } from "@aws-amplify/api";
import Whitelist from '../components/whitelist';
import { Auth } from 'aws-amplify';

// Settings for side menu
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));
  
/**
 * Home page
 * 
 * @param cognitoUser 
 * @returns 
 */
function Home(cognitoUser: any) {

  // determines which component is shown in main area when clicking on a sidebar menu item
  const [showContent, setShowContent] = useState<string>("logList");
  // show or hide loading overlay
  const [showLoginOverlay, setShowLoginOverlay] = useGlobalState("showLoginOverlay");
  // show or hide sidebar menu
  const [sideMenuOpen, setSideMenuOpen] = useState(true);
  // Set logged in user
  const [loggedInUser, setLoggedInUser] = useGlobalState("loggedInUser");
  
  useEffect(() => {    
    // Retrieve current user
    fetchUser();
    // Close login overlay if visible
    setShowLoginOverlay(false);    
  }, []);

  const fetchUser = async () => {        
    let email: string = (await Auth.currentSession()).getIdToken().payload.email;    
    const response = await API.graphql(graphqlOperation(getUsers, { id: email })) as GraphQLResult<any>; 
    setLoggedInUser(response.data.getUsers);
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));
  
  
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <ApplicationBar drawerWidth={drawerWidth} sideMenuOpen={sideMenuOpen} setSideMenuOpen={ setSideMenuOpen } />
      <SideMenu open={sideMenuOpen} setOpen={ setSideMenuOpen } width={drawerWidth} showContent={setShowContent}/>
      <Main open={sideMenuOpen}>
        <DrawerHeader />
        {showContent === 'users' && <Users></Users>}
        {showContent === 'jobSettings' && <EditJobSettings></EditJobSettings>}
        {showContent === 'logList' && <LogList></LogList>}
        {showContent === 'whitelist' && <Whitelist></Whitelist>}
      </Main>
    </Box>
  )
}

export default Home;