import { createGlobalState } from 'react-hooks-global-state';
import { User } from '../components/users';
import { Users } from '../models';

interface GlobalState {  
  loggedInUser: Users|null,
  users: User[],
  showLoadingOverlay: boolean,
  showLoginOverlay: boolean
}
  
/**
 * Provide global state functionality.
 */
export const { useGlobalState, setGlobalState } = createGlobalState<GlobalState>({  
  loggedInUser: null,
  users: [] as User[],
  showLoadingOverlay: false,
  showLoginOverlay: false
});

export const resetGlobalState = () => { 
  setGlobalState("loggedInUser", null);
  setGlobalState("users", [] as User[]);
  setGlobalState("showLoadingOverlay", false);
  setGlobalState("showLoginOverlay", false);
}
