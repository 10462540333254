
import React, { useEffect, useState } from 'react';

import { API, graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import { listUsers, resendTempPassword } from '../graphql/queries';
import { addUser, updateUsers } from '../graphql/mutations';

import { useGlobalState } from '../helper/state';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import RefreshIcon from '@mui/icons-material/Refresh';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


import { string as stringSchema } from 'yup';
import LoadingOverlay from './loadingOverlay';
import { UserRole } from '../models';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';

// Configure alert snackbar
const Alert = React.forwardRef(function Alert(props: any, ref: any) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export interface User {
  id: string,
  email: string,
  role: string,
  type: string,
  cognitoStatus?: string
  sendEmailNotificationStatus?: string
}

function Users() {

  // Schema for email validation with YUP library
  const emailSchema = stringSchema().email();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showLoadingOverlay, setShowLoadingOverlay] = useGlobalState("showLoadingOverlay");
  // List of users in global state
  const [users, setUsers] = useGlobalState("users");
  // Loading used for disabling buttons in modal
  const [loading, setLoading] = useState(false);
  // Show or hide modal window for adding users
  const [showModal, setShowModal] = useState(false);
  // Configure meesage for snackbar alerts. If show=true message will be shown. Possible types: success, error...
  const [showToast, setShowToast] = useState({ message: "", show: false, type: "" });
  // New user configured in modal window
  const [newUser, setNewUser] = useState({ email: "", role: "USER", type: "GUEST" });
  // If emailError is != "" error message will show up below email input field in modal window
  const [emailError, setEmailError] = useState("");
  
  const [loggedInUser] = useGlobalState("loggedInUser");
  
  useEffect(() => {
    // If no users loaded get userlist from API
    if (users.length === 0) {
      fetchUsers();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  /**
   * Load user from API.
   */
  async function fetchUsers() {
    setShowLoadingOverlay(true);
    let result: User[] = [];
    let response = null;
    do {
      if (response && response.data.listUsers.nextToken) {
        response = await API.graphql(graphqlOperation(listUsers, { nextToken: response.data.listUsers.nextToken })) as GraphQLResult<any>;
      } else {
        response = await API.graphql(graphqlOperation(listUsers)) as GraphQLResult<any>;
      }
      result = [...result, ...response.data.listUsers.items];
    } while (response.data.listUsers.nextToken);    
    setUsers(result);
    setShowLoadingOverlay(false);
  }
  
  /**
   * Handle change of input fields. Resets error messages.
   * @param event 
   */
  const handleOnChange = (event: any) => {
    setEmailError("");
    setNewUser(prevState => ({ ...prevState!, [event.target.name]: event.target.value }));
  };

  /**
   * Save new user from modal window.
   * 
   * @param event 
   * @returns 
   */
  const handleSaveUser = async (event: any) => {
    // Do not save if email is invalid
    if (!checkIsEmailValid()) {
      return;
    }
    try {
      setLoading(true);
      const response = await API.graphql(graphqlOperation(addUser, { input: newUser })) as GraphQLResult<any>;
      if (!response.data || !response.data.addUser) {
        throw new Error();
      }    
      setUsers(prev => [...prev, response.data.addUser]);
      setShowToast({ message: "Benutzer wurde angelegt und eine Einladungsnachricht gesendet.", show: true, type: "success" });
    } catch (err) {
      console.error(err);
      const error = err as any;
      if (error.errors[0].message === 'DOMAIN_ERROR') {
        setShowToast({ message: `Der Benutzer konnte nicht gespeichert werden.\n\nDie Domain "${newUser.email.split('@')[1]}" gehört nicht zu deiner Organisation.`, show: true, type: "error" }); 
      } else {
        setShowToast({ message: "Der Benutzer konnte nicht gespeichert werden.", show: true, type: "error" });
      }
    } finally {
      setShowModal(false);
      setLoading(false);
      resetNewUser();
    }
  };

  /**
   * Resend the invitation mail with a new temporary password.
   * 
   * @param event event
   * @param email email address
   */
  const handleResendTempPassword = async (event: any, email: string) => {
try {
      setLoading(true);
      const response = await API.graphql(graphqlOperation(resendTempPassword, { email: email })) as GraphQLResult<any>;
      if (!response.data || !response.data.resendTempPassword) {
        throw new Error();
      }          
      setShowToast({ message: "Die Einladung wurde erneut versendet.", show: true, type: "success" });
    } catch (err) {
      console.error(err);
      setShowToast({ message: "Die Einladung konnte nicht erneut gesendet werden.", show: true, type: "error" });      
    } finally {      
      setLoading(false);      
    }
  }

  /**
   * Reset new user input fields and object
   */
  const resetNewUser = () => {
    setNewUser({ email: "", role: "USER", type: "GUEST" });
  }

  /**
   * Cancel new user modal window.
   */
  const cancelModal = () => {
    setEmailError("");
    setShowModal(false);
    resetNewUser();
  }

  /**
   * Show or hide new user modal
   */
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  /**
   * Close modal window if no API request is active.
   */
  const handleCloseModal = () => {
    if (!loading) {
      cancelModal();
    }
  }

  /**
   * Close snackbar alert.
   * 
   * @param event 
   * @param reason 
   * @returns 
   */
  const handleSnackbarClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(prev => ({ ...prev, show: false }));
  };

  const handleNotificationStatusChange = async (user: User, checked: boolean) => {

    try {
      setLoading(true);
      const response = await API.graphql(graphqlOperation(updateUsers, { input: {id: user.id, sendEmailNotificationStatus: checked ? 'SEND_HIT' : 'OFF'} })) as GraphQLResult<any>;
      if (!response.data || !response.data.updateUsers) {
        throw new Error();
      }    
      user.sendEmailNotificationStatus = checked ? 'SEND_HIT' : 'OFF';
      setUsers(prev => [...prev]);      
    } catch (err) {
      console.error(err);
      setShowToast({ message: "Der Benachrichtigungs-Status konnte nicht gespeichert werden.", show: true, type: "error" });
    } finally {      
      setLoading(false);      
    }

  }

  /**
   * Check if email in new user modal is valid and does not already exist in user list.
   * @returns 
   */
  const checkIsEmailValid = () => {
    if (!emailSchema.isValidSync(newUser.email) || newUser.email.trim() === "") {
      setEmailError("Die E-Mail Adresse ist ungültig.")
      return false;
    } else {
      const exists = users.findIndex((user) => user.email === newUser.email);
      if (exists > -1) {
        setEmailError("Der Benutzer existiert bereits.");
        return false;
      } else {
        setEmailError("");
        return true;
      }
    }
  }
    
  return (   
    <>
      <LoadingOverlay/>
      <TableContainer component={Paper} elevation={1} sx={{minWidth: 650, maxWidth: "100%" }}>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell>
                {loggedInUser?.role === UserRole.ADMIN &&
                  <IconButton onClick={toggleModal}>
                    <PersonAddIcon />
                  </IconButton>
                }
                <IconButton sx={{ marginRight: "15px" }} onClick={fetchUsers}>
                  <RefreshIcon />
                </IconButton>
                E-Mail Adresse
              </TableCell>
              <TableCell>Rolle</TableCell>
              <TableCell>Typ</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align={"center"}>Benachrichtigung</TableCell>
              <TableCell align={"center"}>Aktion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user: User, index) => (
              <TableRow key={`row_${index}`} hover>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>{user.type}</TableCell>
                <TableCell>{user.cognitoStatus}</TableCell>
                <TableCell align={"center"}>
                  <Tooltip title={!user.sendEmailNotificationStatus || user.sendEmailNotificationStatus === "OFF" ? 'Einschalten' : 'Ausschalten'} placement="top">
                    <Switch
                      checked={user.sendEmailNotificationStatus === "SEND_HIT"}
                      onChange={(event) => handleNotificationStatusChange(user, event.target.checked)}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {user.cognitoStatus !== "CONFIRMED" &&
                    <Tooltip title="Einladung erneut versenden">
                      <IconButton onClick={(event) => handleResendTempPassword(event, user.email)} size={"small"}>
                        <RefreshIcon fontSize="medium" />
                      </IconButton>
                    </Tooltip>
                  }
                </TableCell>
              </TableRow>
            ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={showModal} onClose={handleCloseModal}>
        <DialogTitle>Benutzer hinzufügen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ein neuer Benutzer wird hinzugefügt und eine Einladungsbenachrichtigung mit temporärem Passwort per E-Mail gesendet.
          </DialogContentText>
          <TextField
            margin="dense"
            required
            autoFocus
            id="email"
            label="E-Mail Adresse"
            type="email"
            fullWidth
            variant="standard"
            value={newUser.email}
            onChange={handleOnChange}
            name="email"
            error={emailError !== ""}
            helperText={emailError}
          />
          <FormControl variant="standard" sx={{ mt: 2, width: "100%" }}>
            <InputLabel id="role-label">Rolle</InputLabel>
            <Select
              labelId="role-label"
              id="role"
              value={newUser.role}
              label="Rolle"
              onChange={handleOnChange}
              name="role"
              input={<Input />}
            >
              <MenuItem value={"USER"}>USER</MenuItem>
              <MenuItem value={"ADMIN"}>ADMIN</MenuItem>
            </Select>
          </FormControl>
          {/* <FormControl variant="standard" sx={{ mt: 2, width: "100%" }}>
            <InputLabel id="type-label">Typ</InputLabel>
            <Select
              labelId="type-label"
              id="type"
              value={newUser.type}
              label="Type"
              onChange={handleOnChange}
              name="type"
              input={<Input />}
            >
              <MenuItem value={"ORGANIZATIONAL"}>ORGANIZATIONAL</MenuItem>
              <MenuItem value={"GUEST"}>GUEST</MenuItem>
            </Select>
          </FormControl> */}
        </DialogContent>
        <DialogActions>
          <CircularProgress sx={{ visibility: loading ? 'visible' : 'hidden' }} size={30} />
          <Button onClick={cancelModal} disabled={loading}>Abbrechen</Button>
          <Button onClick={handleSaveUser} disabled={loading}>Speichern</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={showToast.show} autoHideDuration={5000} onClose={handleSnackbarClose} sx={{maxWidth: '500px', whiteSpace: 'pre-wrap'}}>
        <Alert onClose={handleSnackbarClose} severity={showToast.type} sx={{ width: '100%' }}>
          {showToast.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default Users;