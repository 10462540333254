import { useState, useReducer, useEffect, forwardRef } from "react";
import Grid from '@mui/material/Grid';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Switch from '@mui/material/Switch';

import { string as stringSchema } from 'yup';
import MuiAlert from '@mui/material/Alert';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import LoadingOverlay from "./loadingOverlay";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton/IconButton";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useGlobalState } from "../helper/state";
import { API, GraphQLResult, graphqlOperation } from "@aws-amplify/api";
import { listJobSettings } from "../graphql/queries";
import { createJobSettings, deleteJobSettings, updateJobSettings } from "../graphql/mutations";
import Snackbar from "@mui/material/Snackbar";
import { objectMap } from "../helper/utils";
import DeleteIcon from '@mui/icons-material/Delete';

// Configure alert snackbar
const Alert = forwardRef(function Alert(props: any, ref: any) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// HTTP request types which user can select
export enum RequestType {
  GET = "GET",
  POST = "POST",
  PUT = "PUT"
}

// JobSettings interface
export interface JobSettings {
  id: string
  name: string
  active: boolean
  url: string
  requestType: RequestType
  startDateParameterName: string
  authorizationHeader: string
  lastRunDate: string
  mmapping_firstName: string
  mapping_middleName: string
  mapping_lastName: string
  mapping_wholeName: string
  mapping_city: string
  mapping_countryDescription: string
  mapping_id: string
}

// ValueType is used for the GUI elements state
interface ValueType {
  value: string | boolean | RequestType
  touched: boolean
  error: string
}

// State for the GUI elements which maps to JobSettings object/interface.
type State = {
  name: ValueType
  active: ValueType
  url: ValueType
  requestType: ValueType
  startDateParameterName: ValueType
  authorizationHeader: ValueType
  mapping_firstName: ValueType
  mapping_middleName: ValueType
  mapping_lastName: ValueType
  mapping_wholeName: ValueType
  mapping_city: ValueType
  mapping_countryDescription: ValueType
  mapping_id: ValueType
};

// Initial (and reset) value for the GUI state
const initialValue = {
  name: { value: "", touched: false, error: ""},
  active: { value: false, touched: false, error: ""},
  url: { value: "", touched: false, error: ""},
  requestType: { value: RequestType.GET, touched: false, error: ""},
  startDateParameterName: { value: "", touched: false, error: "" },
  authorizationHeader: { value: "", touched: false, error: ""},
  mapping_firstName: { value: "", touched: false, error: ""},
  mapping_middleName: { value: "", touched: false, error: ""},
  mapping_lastName: { value: "", touched: false, error: ""},
  mapping_wholeName: { value: "", touched: false, error: ""},
  mapping_city: { value: "", touched: false, error: ""},
  mapping_countryDescription: { value: "", touched: false, error: ""},
  mapping_id: { value: "", touched: false, error: "" },
  fuzzy: {value: false, touched: false, error: ""}
};

// Definition of reducer actions
type Action =
  | {
      type: 'update';
      payload: {
        key: string;
        value: string|boolean;
        error: string;
      };
    }
  | { type: 'reset' }
  | {
    type: 'updateAll';
    payload: {
        key: string;
        value: string|boolean;
        error: string;
      }[];
  }
  | {
    type: 'set';
    jobSettings: JobSettings
  }

/**
 * Reducer function for state management of GUI elements.
 * 
 * @param state Current state
 * @param action Action to be performed
 * @returns new state
 */
const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'update': // Update single GUI element defined in payload
      return { ...state, [action.payload.key]: { value: action.payload.value, touched: true, error: action.payload.error } };
    case 'updateAll': // Update multiple GUI elements defined in payload array
      const parms = action.payload.reduce((prev: any, cur: any) => ({...prev, [cur.key]: {value: cur.value, touched: true, error: cur.error }}), {});      
      return {
        ...state, ...parms
      };
    case 'reset': // Reset GUI elements to initial value (empty)
      return initialValue;    
    case 'set': // Apply values of a JobSettings object to GUI elements.
      // Map JobSettings object to State properties.
      return objectMap(action.jobSettings, (v: any) => ({ value: v, touched: false, error: '' }));
    default:
      throw new Error(`Unknown action type`);
  }
};

/**
 * EditJobSettings react function definition.
 * 
 * @returns Components
 */
function EditJobSettings() {
  
  // State for GUI elements
  const [state, dispatch] = useReducer(reducer, initialValue);
  // List of JobSettings from DB
  const [jobSettingsList, setJobSettingsList] = useState<JobSettings[]>([] as JobSettings[]);
  // State for loading indicator
  const [loading, setLoading] = useState(false);
  // State for showing edit job settings modal
  const [showModal, setShowModal] = useState(false);
  // State for showing loading overlay
  const [showLoadingOverlay, setShowLoadingOverlay] = useGlobalState("showLoadingOverlay");
  // Configure meesage for snackbar alerts. If show=true message will be shown. Possible types: success, error...
  const [showToast, setShowToast] = useState({ message: "", show: false, type: "" });
  
  // Validator for url text field
  const urlSchema = stringSchema().url();
  
  /**
   * Load JobSettings from DB on page load
   */
  useEffect(() => {
    fetchJobSettings();
  }, []);

  /**
   * Handle changes in GUI elements
   * @param event 
   */
  const handleChange = (event: any) => {    
    // Call dispatch event of reducer    
    dispatch({
      type: 'update',
      payload: { key: event.target.name, value: event.target.name === 'fuzzy' ? event.target.checked : event.target.value, error: '' }
    });    
  };

  /**
   * Load Job Settings from DB.
   */
  const fetchJobSettings = async () => {
    setShowLoadingOverlay(true);
    let response = null;
    let result: JobSettings[] = [];
    
    // Load all job settings, resocgnize next token
    do {
      if (response && response.data.listJobSettings.nextToken) {
        response = await API.graphql(graphqlOperation(listJobSettings, { nextToken: response.data.listJobSettings.nextToken })) as GraphQLResult<any>;
      } else {
        response = await API.graphql(graphqlOperation(listJobSettings)) as GraphQLResult<any>;
      }
      result = [...result, ...response.data.listJobSettings.items];
    } while (response.data.listJobSettings.nextToken);
    
    setJobSettingsList(result);
    setShowLoadingOverlay(false);
  };

  /**
   * Open or close modal dialog for editing job settings.
   */
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  /**
   * Reset GUI elements values to initial value on closing modal dialog.
   */    
  const handleCloseModal = () => {
    dispatch({
      type: 'reset'      
    });
    setShowModal(false);
  };

  /**
   * Check a single field or all fields which have validation. Error message is shown in GUI.
   * 
   * @param fieldName Name of field to be checked. If empty all checkable fields will be vlidated.
   * @returns 
   */
  const validateFields = (fieldName?: string): boolean => {
    let hasError = false;
    const payload = [];

    // Check url field
    if ((!fieldName || fieldName === 'url') && (!urlSchema.isValidSync(state.url.value) || state.url.value === '')) {
      hasError = true;
      payload.push({
        key: 'url',
        value: state.url.value as string,
        error: 'Die URL ist nicht gültig.'
      });
    }

    // Check start parameter field
    if ((!fieldName || fieldName === 'startDateParameterName') && state.startDateParameterName.value === '') {
      hasError = true;
      payload.push({
        key: 'startDateParameterName',
        value: state.startDateParameterName.value as string,
        error: 'Das Feld darf nicht leer sein.'
      });
    }
 
    // Update state if there is at least on error.
    if (payload.length > 0) {
      dispatch(
        {
          type: 'updateAll',
          payload: payload
        }
      );      
    }
    return !hasError;
  }

  /**
   * Handles click on save button in modal dialog.
   * 
   * @returns void
   */
  const handleSave = async () => {
    // Do not save if url or startDateParameter field is invalid
    if (!validateFields()) {      
      return;
    }
    // Map State properties to JobSettings object.
    const jobSettings = objectMap(state, (v: ValueType) => v.value);
    
    try {
      setLoading(true);
      
      if (!jobSettings.id) { // Create new job settings entry in DB
         const response = await API.graphql(graphqlOperation(createJobSettings, { input: jobSettings })) as GraphQLResult<any>;
        if (!response.data || !response.data.createJobSettings) {
          throw new Error();
        }
        setJobSettingsList(prev => [...prev, response.data.createJobSettings]);
      } else { // update job settings entry in DB
        delete jobSettings.createdAt;
        delete jobSettings.updatedAt;

        const response = await API.graphql(graphqlOperation(updateJobSettings, { input: jobSettings })) as GraphQLResult<any>;
        if (!response.data || !response.data.updateJobSettings) {
          throw new Error();
        }        
        // Reload job settings for displaying in GUI
        await fetchJobSettings()
      }
      
      setShowToast({ message: "Die Job Einstellungen wurden gespeichert.", show: true, type: "success" });
    } catch (err) {      
      setShowToast({ message: "Die Job Einstellungen konnten nicht gespeichert werden.", show: true, type: "error" });
    } finally {
      setShowModal(false);
      setLoading(false);
      // Reset State fields
      dispatch({
        type: 'reset'
      });
    }
  };

  /**
   * Handles closing of alert messages.
   * 
   * @param event 
   * @param reason 
   * @returns 
   */
  const handleSnackbarClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(prev => ({ ...prev, show: false }));
  };

  /**
   * Handles click on edit button in table.
   * 
   * @param event 
   * @param jobSettings 
   */
  const handleEditClick = (event:any, jobSettings: JobSettings) => {
    dispatch({
      type: 'set',
      jobSettings: jobSettings
    });
    setShowModal(true);
  };

  /**
   * Handles click on delete button in table.
   * 
   * @param event 
   * @param jobSettings 
   */
  const handleDeleteClick = async (event: any, jobSettings: JobSettings) => {
    setShowLoadingOverlay(true);
    try {
      const response = await API.graphql(graphqlOperation(deleteJobSettings, { input: { id: jobSettings.id } })) as GraphQLResult<any>;
      if (!response.data || !response.data.deleteJobSettings) {
        throw new Error();
      }
      // reload job settings from db for displaying in GUI.
      await fetchJobSettings();
      setShowToast({ message: "Die Job Einstellungen wurden gelöscht.", show: true, type: "success" });
    } catch (err) {
      setShowToast({ message: "Die Job Einstellungen konnten nicht gelöscht werden.", show: true, type: "error" });
    } finally {
      setShowLoadingOverlay(false);
    }
  };

  /**
   * Handles click on status switch.
   * 
   * @param settings JobSettings object
   */
  const handleStatusChange = async (settings: JobSettings) => {
    setShowLoadingOverlay(true);
    try {
      const response = await API.graphql(graphqlOperation(updateJobSettings, { input: { id: settings.id, active: !settings.active } })) as GraphQLResult<any>;
      if (!response.data || !response.data.updateJobSettings) {
        throw new Error();
      }
      settings.active = !settings.active;
      setJobSettingsList([...jobSettingsList]);
    } catch (err) {
      console.error(err);
    } finally {
      setShowLoadingOverlay(false);
    }
  }; 

  return (
    <>
      <LoadingOverlay />
      <TableContainer component={Paper} elevation={1} sx={{ minWidth: 650, maxWidth: "100%" }}>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell>
                <IconButton onClick={toggleModal}>
                  <AddIcon />
                </IconButton>
                <IconButton sx={{ marginRight: "15px" }} onClick={fetchJobSettings}>
                  <RefreshIcon />
                </IconButton>
                Job Name
              </TableCell>
              <TableCell>Endpoint Url</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Letzte Ausführung</TableCell>              
              <TableCell align={"center"}>Aktion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobSettingsList.map((settings: JobSettings, index) => (
              <TableRow key={`row_${index}`} hover>
                <TableCell>{settings.name}</TableCell>
                <TableCell>{settings.url}</TableCell>
                <TableCell>
                  <Switch
                    checked={settings.active}
                    onChange={() => handleStatusChange(settings)}
                  />
                </TableCell>
                <TableCell>{settings.lastRunDate}</TableCell>                
                <TableCell align={"center"}>                  
                  <IconButton onClick={(event) => handleEditClick(event, settings)} size={"small"}>
                    <EditIcon sx={{fontSize: "medium"}} />
                  </IconButton>
                  <IconButton onClick={(event) => handleDeleteClick(event, settings)} size={"small"}>
                    <DeleteIcon sx={{fontSize: "medium"}} />
                </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={showModal} onClose={handleCloseModal} maxWidth={"md"} fullWidth>
        <DialogTitle>Job hinzufügen</DialogTitle>
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                required={false}
                id="name"
                label="Job Name"
                fullWidth
                variant="standard"
                value={state.name.value}
                onChange={handleChange}
                name="name"
                error={state.name.error !== ""}
                helperText={state.name.error} />
            </Grid>
            <Grid item xs={3}>
              <FormControl variant="standard" sx={{ mt: 2, width: "100%" }}>
                <InputLabel id="requestType-label">Request-Typ</InputLabel>
                <Select
                  labelId="requestType-label"
                  id="requestType"
                  value={state.requestType.value}
                  onChange={handleChange}
                  name="requestType"
                >
                  <MenuItem value={"GET"}>GET</MenuItem>
                  <MenuItem value={"POST"}>POST</MenuItem>
                  <MenuItem value={"PUT"}>PUT</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={9}>
              <TextField
                margin="normal"
                required
                autoFocus
                id="url"
                label="URL"
                fullWidth
                variant="standard"
                value={state.url.value}
                onChange={handleChange}
                name="url"
                error={state.url.error !== ""}
                helperText={state.url.error} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required={state.requestType.value === RequestType.GET}
                style={{ visibility: state.requestType.value === RequestType.GET ? "visible" : "hidden" }}
                id="startDateParameterName"
                label="Startdatum Parameter"
                fullWidth
                variant="standard"
                value={state.startDateParameterName.value}
                onChange={ handleChange }
                name="startDateParameterName"
                error={state.startDateParameterName.error !== ""}
                helperText={state.startDateParameterName.error} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required={false}
                id="authorizationHeader"
                label="Authorization Header"
                fullWidth
                variant="standard"
                value={state.authorizationHeader.value}
                onChange={handleChange}
                name="authorizationHeader"
                error={state.authorizationHeader.error !== ""}
                helperText={state.authorizationHeader.error} />
            </Grid>            
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required={true}
                id="mapping_id"
                label="id"
                fullWidth
                variant="standard"
                value={state.mapping_id.value}
                onChange={handleChange}
                name="mapping_id"
                error={state.mapping_id.error !== ""}
                helperText={state.mapping_id.error} />
            </Grid>
            {/* <Grid item xs={3}>
              <TextField
                margin="normal"
                required={false}
                id="mapping_firstName"
                label="firstName"
                fullWidth
                variant="standard"
                value={state.mapping_firstName.value}
                onChange={handleChange}
                name="mapping_firstName"
                error={state.mapping_firstName.error !== ""}
                helperText={state.mapping_firstName.error} />
            </Grid>
            <Grid item xs={3}>
              <TextField
                margin="normal"
                required={false}
                id="mapping_middleName"
                label="middleName"
                fullWidth
                variant="standard"
                value={state.mapping_middleName.value}
                onChange={handleChange}
                name="mapping_middleName"
                error={state.mapping_middleName.error !== ""}
                helperText={state.mapping_middleName.error} />
            </Grid>
            <Grid item xs={3}>
              <TextField
                margin="normal"
                required={false}
                id="mapping_lastName"
                label="lastName"
                fullWidth
                variant="standard"
                value={state.mapping_lastName.value}
                onChange={handleChange}
                name="mapping_lastName"
                error={state.mapping_lastName.error !== ""}
                helperText={state.mapping_lastName.error} />
            </Grid> */}
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required={false}
                id="mapping_wholeName"
                label="wholeName"
                fullWidth
                variant="standard"
                value={state.mapping_wholeName.value}
                onChange={handleChange}
                name="mapping_wholeName"
                error={state.mapping_wholeName.error !== ""}
                helperText={state.mapping_wholeName.error} />
            </Grid>
            {/* <Grid item xs={3}>
              <TextField
                margin="normal"
                required={false}
                id="mapping_city"
                label="city"
                fullWidth
                variant="standard"
                value={state.mapping_city.value}
                onChange={handleChange}
                name="mapping_city"
                error={state.mapping_city.error !== ""}
                helperText={state.mapping_city.error} />
            </Grid> */}
            {/* <Grid item xs={3}>
              <TextField
                margin="normal"
                required={false}
                id="mapping_countryDescription"
                label="countryDescription"
                fullWidth
                variant="standard"
                value={state.mapping_countryDescription.value}
                onChange={handleChange}
                name="mapping_countryDescription"
                error={state.mapping_countryDescription.error !== ""}
                helperText={state.mapping_countryDescription.error} />
            </Grid>             */}
              <Grid item xs={6} sx={{marginTop: "20px"}}>
                <input id="fuzzy" onChange={handleChange} name="fuzzy" value={state.fuzzy.value}  type="checkbox" checked={state.fuzzy.value} />
                <label htmlFor="fuzzy">Fuzzysuche aktivieren</label>
              </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CircularProgress sx={{ visibility: loading ? 'visible' : 'hidden' }} size={30} />
          <Button onClick={handleCloseModal} disabled={loading}>Abbrechen</Button>
          <Button onClick={handleSave} disabled={loading}>Speichern</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={showToast.show} autoHideDuration={5000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={showToast.type} sx={{ width: '100%' }}>
          {showToast.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default EditJobSettings;

