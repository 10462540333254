import { Authenticator } from '@aws-amplify/ui-react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import { Auth } from '@aws-amplify/auth';
import { useEffect } from 'react';
import { API } from '@aws-amplify/api';
import { useGlobalState } from './helper/state';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { SignInFooter } from './components/signInFooter';
import { SignInHeader } from './components/signinHeader';
import awsExports from "./aws-exports";

function App() {

  // Used for loading overlay on login. Login needs its own loading overlay because the Amplify UI calls this site as redirect
  // and there is a delay until home page is rendered.
  const [showLoginOverlay, setShowLoginOverlay] = useGlobalState("showLoginOverlay");  

  /**
   * Reads login config data from API. As there is no possibility to add dynamic environment variables to frontend (depending on amplify stage)
   * we need this API call to get login parameters for the current environment.
   */
  const initLoginConfig = async () => {    
    // Ensure that API is only called on login and not on every rerender (reload) of the application
    if (!window.sessionStorage.getItem("cognitoHostedUIUrl")) {
      const apiName = 'loginConfigApi';
      const path = `/loginConfig`;
      const response = await API.get(apiName, path, {});
      
      window.sessionStorage.setItem("cognitoHostedUIUrl", response.hosted_ui_url);
      window.sessionStorage.setItem("loginCallbackUrl", response.login_callback_url);
      window.sessionStorage.setItem("logoutCallbackUrl", response.logout_callback_url);      
    }
    // Configure amplify auth parameters
    Auth.configure({
        region: awsExports.aws_project_region,
        userPoolId: awsExports.aws_user_pools_id,
        userPoolWebClientId: awsExports.aws_user_pools_web_client_id,
        identityPoolId: awsExports.aws_cognito_identity_pool_id,
        oauth: {
          domain: window.sessionStorage.getItem("cognitoHostedUIUrl"),
          scope: ["email", "openid", "profile"],
          redirectSignIn: window.sessionStorage.getItem("loginCallbackUrl"),
          redirectSignOut: window.sessionStorage.getItem("logoutCallbackUrl"),
          responseType: "code"
        }
      });
  }

  useEffect(() => {
    // If user is in login process show loading overlay
    setShowLoginOverlay(!!window.sessionStorage.getItem("showLoginOverlay"));
    // Prevent showing loading overlay on page refresh after login  
    window.sessionStorage.removeItem("showLoginOverlay");        
    // Initialize the login parameters
    initLoginConfig();    
  }, []);

  return (
    // <>
    //   <Backdrop
    //     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    //     open={showLoginOverlay}
    //   >
    //     <CircularProgress color="inherit" />
    //   </Backdrop>
      <Authenticator variation='modal' hideSignUp={true} components={{
        SignIn: {
          Header: SignInHeader,
          Footer: SignInFooter
        }
      }}>
        {(user) => (
          <main>
            <Routes>
              <Route path="/">
                <Route index element={<Home cognitoUser={user} />} />                
              </Route>
            </Routes>
          </main>
        )}
      </Authenticator>
    // </>
  );
}

export default App;
