/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createJobSettings = /* GraphQL */ `
  mutation CreateJobSettings(
    $input: CreateJobSettingsInput!
    $condition: ModelJobSettingsConditionInput
  ) {
    createJobSettings(input: $input, condition: $condition) {
      id
      name
      active
      url
      requestType
      startDateParameterName
      lastRunDate
      authorizationHeader
      mapping_firstName
      mapping_middleName
      mapping_lastName
      mapping_wholeName
      mapping_city
      mapping_countryDescription
      mapping_id
      fuzzy
      createdAt
      updatedAt
    }
  }
`;
export const updateJobSettings = /* GraphQL */ `
  mutation UpdateJobSettings(
    $input: UpdateJobSettingsInput!
    $condition: ModelJobSettingsConditionInput
  ) {
    updateJobSettings(input: $input, condition: $condition) {
      id
      name
      active
      url
      requestType
      startDateParameterName
      lastRunDate
      authorizationHeader
      mapping_firstName
      mapping_middleName
      mapping_lastName
      mapping_wholeName
      mapping_city
      mapping_countryDescription
      mapping_id
      fuzzy
      createdAt
      updatedAt
    }
  }
`;
export const deleteJobSettings = /* GraphQL */ `
  mutation DeleteJobSettings(
    $input: DeleteJobSettingsInput!
    $condition: ModelJobSettingsConditionInput
  ) {
    deleteJobSettings(input: $input, condition: $condition) {
      id
      name
      active
      url
      requestType
      startDateParameterName
      lastRunDate
      authorizationHeader
      mapping_firstName
      mapping_middleName
      mapping_lastName
      mapping_wholeName
      mapping_city
      mapping_countryDescription
      mapping_id
      fuzzy
      createdAt
      updatedAt
    }
  }
`;
export const createLog = /* GraphQL */ `
  mutation CreateLog(
    $input: CreateLogInput!
    $condition: ModelLogConditionInput
  ) {
    createLog(input: $input, condition: $condition) {
      id
      jobName
      date
      createdAt
      listType
      listDate
      listId
      numberOfHits
      numberOfHitsWhitelisted
      note
      updatedBy {
        id
        email
        role
        type
        cognitoStatus
        sendEmailNotificationStatus
        createdAt
        updatedAt
      }
      status
      hits {
        nextToken
      }
      numberOfSearchEntities
      updatedAt
      logUpdatedById
    }
  }
`;
export const updateLog = /* GraphQL */ `
  mutation UpdateLog(
    $input: UpdateLogInput!
    $condition: ModelLogConditionInput
  ) {
    updateLog(input: $input, condition: $condition) {
      id
      jobName
      date
      createdAt
      listType
      listDate
      listId
      numberOfHits
      numberOfHitsWhitelisted
      note
      updatedBy {
        id
        email
        role
        type
        cognitoStatus
        sendEmailNotificationStatus
        createdAt
        updatedAt
      }
      status
      hits {
        nextToken
      }
      numberOfSearchEntities
      updatedAt
      logUpdatedById
    }
  }
`;
export const deleteLog = /* GraphQL */ `
  mutation DeleteLog(
    $input: DeleteLogInput!
    $condition: ModelLogConditionInput
  ) {
    deleteLog(input: $input, condition: $condition) {
      id
      jobName
      date
      createdAt
      listType
      listDate
      listId
      numberOfHits
      numberOfHitsWhitelisted
      note
      updatedBy {
        id
        email
        role
        type
        cognitoStatus
        sendEmailNotificationStatus
        createdAt
        updatedAt
      }
      status
      hits {
        nextToken
      }
      numberOfSearchEntities
      updatedAt
      logUpdatedById
    }
  }
`;
export const createCheckedEntities = /* GraphQL */ `
  mutation CreateCheckedEntities(
    $input: CreateCheckedEntitiesInput!
    $condition: ModelCheckedEntitiesConditionInput
  ) {
    createCheckedEntities(input: $input, condition: $condition) {
      id
      logId
      entities
      createdAt
      updatedAt
    }
  }
`;
export const updateCheckedEntities = /* GraphQL */ `
  mutation UpdateCheckedEntities(
    $input: UpdateCheckedEntitiesInput!
    $condition: ModelCheckedEntitiesConditionInput
  ) {
    updateCheckedEntities(input: $input, condition: $condition) {
      id
      logId
      entities
      createdAt
      updatedAt
    }
  }
`;
export const deleteCheckedEntities = /* GraphQL */ `
  mutation DeleteCheckedEntities(
    $input: DeleteCheckedEntitiesInput!
    $condition: ModelCheckedEntitiesConditionInput
  ) {
    deleteCheckedEntities(input: $input, condition: $condition) {
      id
      logId
      entities
      createdAt
      updatedAt
    }
  }
`;
export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      id
      email
      role
      type
      cognitoStatus
      sendEmailNotificationStatus
      createdAt
      updatedAt
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      email
      role
      type
      cognitoStatus
      sendEmailNotificationStatus
      createdAt
      updatedAt
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $input: DeleteUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    deleteUsers(input: $input, condition: $condition) {
      id
      email
      role
      type
      cognitoStatus
      sendEmailNotificationStatus
      createdAt
      updatedAt
    }
  }
`;
export const createHits = /* GraphQL */ `
  mutation CreateHits(
    $input: CreateHitsInput!
    $condition: ModelHitsConditionInput
  ) {
    createHits(input: $input, condition: $condition) {
      id
      hits
      hitsWhitelisted
      numberOfHits
      numberOfHitsWhitelisted
      referenceId
      referenceWholeName
      referenceFirstName
      referenceLastName
      referenceCountry
      referenceCity
      status
      logId
      createdAt
      updatedAt
    }
  }
`;
export const updateHits = /* GraphQL */ `
  mutation UpdateHits(
    $input: UpdateHitsInput!
    $condition: ModelHitsConditionInput
  ) {
    updateHits(input: $input, condition: $condition) {
      id
      hits
      hitsWhitelisted
      numberOfHits
      numberOfHitsWhitelisted
      referenceId
      referenceWholeName
      referenceFirstName
      referenceLastName
      referenceCountry
      referenceCity
      status
      logId
      createdAt
      updatedAt
    }
  }
`;
export const deleteHits = /* GraphQL */ `
  mutation DeleteHits(
    $input: DeleteHitsInput!
    $condition: ModelHitsConditionInput
  ) {
    deleteHits(input: $input, condition: $condition) {
      id
      hits
      hitsWhitelisted
      numberOfHits
      numberOfHitsWhitelisted
      referenceId
      referenceWholeName
      referenceFirstName
      referenceLastName
      referenceCountry
      referenceCity
      status
      logId
      createdAt
      updatedAt
    }
  }
`;
export const createWhitelist = /* GraphQL */ `
  mutation CreateWhitelist(
    $input: CreateWhitelistInput!
    $condition: ModelWhitelistConditionInput
  ) {
    createWhitelist(input: $input, condition: $condition) {
      referenceWholeName
      logicalId
      createdAt
      updatedAt
    }
  }
`;
export const updateWhitelist = /* GraphQL */ `
  mutation UpdateWhitelist(
    $input: UpdateWhitelistInput!
    $condition: ModelWhitelistConditionInput
  ) {
    updateWhitelist(input: $input, condition: $condition) {
      referenceWholeName
      logicalId
      createdAt
      updatedAt
    }
  }
`;
export const deleteWhitelist = /* GraphQL */ `
  mutation DeleteWhitelist(
    $input: DeleteWhitelistInput!
    $condition: ModelWhitelistConditionInput
  ) {
    deleteWhitelist(input: $input, condition: $condition) {
      referenceWholeName
      logicalId
      createdAt
      updatedAt
    }
  }
`;
export const addUser = /* GraphQL */ `
  mutation AddUser($input: AddUserInput!) {
    addUser(input: $input) {
      id
      email
      role
      type
      cognitoStatus
      sendEmailNotificationStatus
      createdAt
      updatedAt
    }
  }
`;
export const addToWhitelist = /* GraphQL */ `
  mutation AddToWhitelist($input: [AddToWhitelistInput]) {
    addToWhitelist(input: $input)
  }
`;
export const removeFromWhitelist = /* GraphQL */ `
  mutation RemoveFromWhitelist($input: [RemoveFromWhitelistInput]) {
    removeFromWhitelist(input: $input)
  }
`;
